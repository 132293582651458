import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  d: "M12 6.27009C13.168 5.49409 14.754 5.01709 16.5 5.01709C18.247 5.01709 19.832 5.49409 21 6.27009V19.2701C19.832 18.4941 18.247 18.0171 16.5 18.0171C14.754 18.0171 13.168 18.4941 12 19.2701M12 6.27009V19.2701V6.27009ZM12 6.27009C10.832 5.49409 9.246 5.01709 7.5 5.01709C5.754 5.01709 4.168 5.49409 3 6.27009V19.2701C4.168 18.4941 5.754 18.0171 7.5 18.0171C9.246 18.0171 10.832 18.4941 12 19.2701V6.27009Z",
  stroke: "currentColor",
  "stroke-width": "2",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  fill: "none"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}